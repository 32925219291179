<template>
  <div id="activateId">
    <div class="header" />
    <div class="main">
      <div class="main-up">
        <div class="main-up-left">激活校园ID</div>
        <div class="main-up-right" @click="previousPage">
          <i class="main-up-right-i1 iconfont icon-fanhui" />
          <label class="main-up-right-i2">返回</label>
        </div>
      </div>
      <div class="main-below">
        <el-col :span="20" style="margin: 0px auto">
          <el-tabs
            v-model="activeName"
            tab-position="left"
            style="height: 100%"
          >
            <el-tab-pane label="激活校园ID" name="phoneBack">
              <div class="main-below-tabs">
                <div class="main-below-center">
                  <div class="main-below-center-div1">
                    <div class="main-below-center-div1-label1">
                      <div
                        class="main-below-center-div1-label1-i1"
                        :class="backdrop1"
                      >
                        1
                      </div>
                      <div class="main-below-center-div1-label1-i2">
                        输入信息
                      </div>
                    </div>
                    <div class="main-below-center-div1-label2" />
                    <div class="main-below-center-div1-label3">
                      <div
                        class="main-below-center-div1-label3-i1"
                        :class="backdrop2"
                      >
                        2
                      </div>
                      <div class="main-below-center-div1-label3-i2">激活ID</div>
                    </div>
                    <div class="main-below-center-div1-label4" />
                    <div class="main-below-center-div1-label5">
                      <div
                        class="main-below-center-div1-label5-i1"
                        :class="backdrop3"
                      >
                        3
                      </div>
                      <div
                        class="main-below-center-div1-label5-i2"
                        style="text-align: left; padding-left: 10px"
                      >
                        完成
                      </div>
                    </div>
                  </div>
                  <div class="main-below-center-div2">
                    <!--校验表单-->
                    <el-form
                      ref="verifyForm"
                      :model="verifyForm"
                      :rules="rules1"
                    >
                      <!--姓名-->
                      <el-col v-if="conceal3" :span="24" style="height: 60px">
                        <el-form-item
                          label="姓名"
                          label-width="120px"
                          prop="verifyName"
                        >
                          <el-input
                            v-model="verifyForm.verifyName"
                            type="text"
                            placeholder="请输入姓名"
                            style="width: 240px"
                          />
                        </el-form-item>
                      </el-col>
                      <!--学号、工号-->
                      <el-col v-if="conceal3" :span="24" style="height: 60px">
                        <el-form-item
                          label="学号/工号"
                          label-width="120px"
                          prop="verifyWorkNo"
                        >
                          <el-input
                            v-model="verifyForm.verifyWorkNo"
                            type="text"
                            placeholder="请输入学号/六位工号"
                            style="width: 240px"
                          />
                        </el-form-item>
                      </el-col>
                      <!--证件类型-->
                      <el-col v-if="conceal3" :span="24" style="height: 60px">
                        <el-form-item
                          label="证件类型"
                          label-width="120px"
                          prop="cType"
                        >
                          <el-select
                            v-model="verifyForm.cType"
                            placeholder="请选择证件类型"
                            style="width: 240px"
                          >
                            <el-option
                              v-for="item in cTypeData"
                              :key="item.value"
                              :label="item.name"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <!--身份证号-->
                      <el-col v-if="conceal3" :span="24" style="height: 60px">
                        <el-form-item
                          label="证件号"
                          label-width="120px"
                          prop="verifyIds"
                          :rules="[
                            {
                              required: true,
                              message: '请输入',
                              trigger: 'change',
                            },
                            { validator: isIdcard, trigger: 'blur' },
                          ]"
                        >
                          <el-input
                            v-model="verifyForm.verifyIds"
                            type="text"
                            placeholder="请输入身份证号"
                            style="width: 240px"
                          />
                        </el-form-item>
                      </el-col>
                      <!--下一步-->
                      <el-col
                        v-if="conceal3"
                        :span="24"
                        style="margin: 0px auto; height: 60px; display: flex"
                      >
                        <span class="main-below-center-div2-span1">
                          <el-button
                            type="primary"
                            @click="nextStep('verifyForm')"
                          >下一步</el-button>
                        </span>
                      </el-col>
                    </el-form>

                    <!--表单2-->
                    <el-form
                      ref="activateForm"
                      :model="activateForm"
                      :rules="rules2"
                    >
                      <!--姓名 label-->
                      <el-col v-if="conceal4" :span="24" style="height: 60px">
                        <el-form-item
                          label="姓名"
                          label-width="120px"
                          prop="name"
                        >
                          <el-input
                            v-model="activateForm.name"
                            type="text"
                            style="width: 240px"
                            :disabled="true"
                            placeholder="请输入姓名"
                          />
                        </el-form-item>
                      </el-col>
                      <!--学号/工号 label-->
                      <el-col v-if="conceal4" :span="24" style="height: 60px">
                        <el-form-item
                          label="学号/工号"
                          label-width="120px"
                          prop="workNo"
                        >
                          <el-input
                            v-model="activateForm.workNo"
                            type="text"
                            style="width: 240px"
                            :disabled="true"
                            placeholder="请输入学号/工号"
                          />
                        </el-form-item>
                      </el-col>

                      <!--新密码-->
                      <el-col v-if="conceal4" :span="24" style="height: 60px">
                        <el-form-item
                          label="新密码"
                          label-width="120px"
                          prop="newPwd"
                          :rules="[
                            {
                              required: true,
                              message: '请输入内容',
                              trigger: 'change',
                            },
                            {
                              validator: validateNewPwd,
                              trigger: 'blur',
                            },
                          ]"
                        >
                          <el-input
                            v-model="activateForm.newPwd"
                            type="password"
                            placeholder="请输入新密码"
                            style="width: 240px"
                            @input="monitor(activateForm.newPwd)"
                          />
                        </el-form-item>
                      </el-col>
                      <!--密码强度-->
                      <el-col
                        v-if="conceal4"
                        :span="24"
                        style="height: 30px; display: flex; padding-left: 120px"
                      >
                        <div class="main-below-center-div2-span2">
                          <div :class="styleSheet1" />
                          <div :class="styleSheet2" />
                          <div :class="styleSheet3" />
                          <div class="main-below-center-div2-span2-styleSheet">
                            {{ styleSheet }}
                          </div>
                        </div>
                      </el-col>
                      <!--确认新密码-->
                      <el-col v-if="conceal4" :span="24" style="height: 60px">
                        <el-form-item
                          label="确认新密码"
                          label-width="120px"
                          prop="newPwd2"
                          :rules="[
                            {
                              required: true,
                              message: '请输入内容',
                              trigger: 'change',
                            },
                            {
                              validator: validateNewPwd2,
                              trigger: 'blur',
                            },
                          ]"
                        >
                          <el-input
                            v-model="activateForm.newPwd2"
                            type="password"
                            placeholder="请再次输入新密码"
                            style="width: 240px"
                            @input="monitor2(activateForm.newPwd2)"
                          />
                        </el-form-item>
                      </el-col>
                      <!--手机号验证-->
                      <el-col
                        v-if="conceal4"
                        :span="24"
                        style="margin: 0px auto; height: 120px; display: flex"
                      >
                        <my-verification-of-mobile-phone
                          ref="phone"
                          :label-title1="labelTitle1"
                          :conceal="isConceal"
                          :conceal3="isConceal3"
                          :conceal2="isConceal2"
                          @returnedValue="returnedValue"
                          @getCode="getCode"
                        />
                      </el-col>
                      <!--邮箱-->
                      <el-col v-if="conceal4" :span="24" style="height: 60px">
                        <el-form-item
                          label="绑定邮箱"
                          label-width="120px"
                          prop="email"
                          :rules="[
                            {
                              validator: validateEmail,
                              trigger: 'blur',
                            },
                          ]"
                        >
                          <el-input
                            v-model="activateForm.email"
                            type="text"
                            placeholder="请输入邮箱账号"
                            style="width: 240px"
                          />
                        </el-form-item>
                      </el-col>
                      <!--确认-->
                      <el-col
                        v-if="conceal4"
                        :span="24"
                        style="margin: 0px auto; height: 50px; display: flex"
                      >
                        <span class="main-below-center-div2-span1">
                          <el-button
                            type="primary"
                            @click="confirm"
                          >确 认</el-button>
                        </span>
                      </el-col>

                      <!--提示-->
                      <el-col
                        v-if="conceal4"
                        :span="24"
                        style="margin: 0px auto; height: 60px; display: flex"
                      >
                        <span class="main-below-center-div2-span3">
                          注意：每个账号仅限激活一次！请妥善保管好账号密码。
                        </span>
                      </el-col>
                    </el-form>
                    <!--激活成功-->
                    <el-col
                      v-if="conceal5"
                      :span="24"
                      style="height: 250px; display: flex"
                    >
                      <div class="main-below-center-div2-img">
                        <i
                          class="
                            main-below-center-div2-img-i1
                            iconfont
                            icon-wancheng
                          "
                        />
                        <label
                          class="main-below-center-div2-img-i2"
                        >恭喜！激活成功</label>
                      </div>
                    </el-col>

                    <!--完成-->
                    <el-col
                      v-if="conceal5"
                      :span="24"
                      style="height: 50px; display: flex"
                    >
                      <span class="main-below-center-div2-span1">
                        <el-button
                          type="primary"
                          style="width: 100%"
                          @click="previousPage"
                        >完 成</el-button>
                      </span>
                    </el-col>
                    <!--完成-->
                    <el-col
                      v-if="conceal5"
                      :span="24"
                      style="height: 70px; display: flex; flex-wrap: wrap"
                    >
                      <div class="main-below-center-div2-span4">
                        “恭喜！您已成功激活了
                        <a
                          href="https://nic.jsu.edu.cn/fwzn1/yhzh1/fwjs.htm"
                          class="main-below-center-div2-span4-a"
                          target="_blank"
                        >校园JSUID</a>
                      </div>
                      <div class="main-below-center-div2-span4">
                        系统将在20分钟内为您启用
                        <a
                          href="https://nic.jsu.edu.cn/info/1128/1205.htm"
                          class="main-below-center-div2-span4-a"
                          target="_blank"
                        >吉首大学企业微信</a>
                        欢迎使用。
                      </div>
                      <div class="main-below-center-div2-span4">
                        遇到问题请在
                        <a
                          href="https://nic.jsu.edu.cn/index.htm"
                          class="main-below-center-div2-span4-a"
                          target="_blank"
                        >信息网络中心</a>
                        网站上寻求帮助！
                      </div>
                    </el-col>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </div>
    </div>
  </div>
</template>

<script>
/*
 * 手机验证组件
 */
import MyVerificationOfMobilePhone from '../../components/myVerificationOfMobilePhone/index';

/*
 * 正则校验
 */
import {
  isNewPwdBlank,
  isEmail,
  isNewPwdStrength,
  isNewPwdChinese,
  isNewPwdStrengthStronger,
  isNewPwdStrengthWeak,
  isIdentity
} from '../../util/validate';
/*
 *api
 */
import {
  checkIdentity,
  updateInfo,
  sendSmsForNew,
  getSomething
} from '../../api/index.js';

export default {
  // import引入的组件需要注入到对象中才能使用",
  components: { MyVerificationOfMobilePhone },
  data() {
    // 这里存放数据",
    return {
      cTypeData: [],
      activeName: 'phoneBack',
      backdrop1: 'orange',
      backdrop2: 'white',
      backdrop3: 'white',
      conceal3: true,
      conceal4: false,
      conceal5: false,
      styleSheet: '', // 较弱,一般,较强
      styleSheet1: 'gray', // gray 初色灰色, red1 低红色 red2 中红色, red3 高红色
      styleSheet2: 'gray',
      styleSheet3: 'gray',

      labelTitle1: '绑定手机号',

      isConceal3: true,
      isConceal2: false,
      isConceal: false,

      http: 'http://172.16.9.20:8081',
      /* 校验表单*/
      verifyForm: {
        /*
         * verifyIds: 身份证号
         * verifyWorkNo: 学工号
         * verifyName: 姓名
         * */
        verifyIds: '',
        verifyWorkNo: '',
        verifyName: '',
        cType: 'idcard'
      },
      /* 表单2*/
      activateForm: {
        /*
         * ids : 身份证号
         * name: 姓名
         * workNo: 学工号
         * code: 校验成功码
         * mobileNumber: 绑定的手机号
         * email: 邮箱地址
         * newPwd: 密码
         * newPwd2: 校验新密码
         * smsCode: 手机验证码
         * */
        ids: '',
        name: '',
        workNo: '',
        code: '',
        mobileNumber: '',
        email: '',
        newPwd: '',
        newPwd2: '',
        smsCode: ''
      },

      /* 验证1*/
      rules1: {
        verifyWorkNo: [
          {
            required: true,
            message: '请输入内容',
            trigger: 'change'
          }
        ],
        verifyName: [
          {
            required: true,
            message: '请输入内容',
            trigger: 'change'
          }
        ]
      },

      /* 验证2*/
      rules2: {
        name: [
          {
            required: true,
            message: '请输入内容',
            trigger: 'change'
          }
        ],
        workNo: [
          {
            required: true,
            message: '请输入内容',
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入内容',
            trigger: 'change'
          }
        ]
      },
      valid: false,
      phone: ''
    };
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {},

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {},
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
    this.getType();
  },
  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {},
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {},
  // 生命周期 - 销毁之前",
  beforeDestroy() {},
  destroyed() {},
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {},
  // 方法集合",
  methods: {
    // 获取证件类型
    getType() {
      getSomething().then((res) => {
        const { code, data, msg } = res.data;
        if (code === 200) {
          this.cTypeData = data;

          console.log(data);
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 实时校验
    monitor2(pwd) {
      if (isNewPwdStrength(this.activateForm.newPwd)) {
        if (
          this.activateForm.newPwd != '' &&
          this.activateForm.newPwd === pwd
        ) {
          this.isConceal = true;
          this.isConceal3 = false;
        } else {
          this.isConceal = false;
          this.isConceal2 = false;
          this.isConceal3 = true;
        }
      }
    },
    // 实时校验密码
    monitor(pwd) {
      this.styleSheet = '';
      this.styleSheet1 = 'gray';
      this.styleSheet2 = 'gray';
      this.styleSheet3 = 'gray';
      /* if (this.activateForm.newPwd2 !== pwd) {
          this.isConceal= false;
          this.isConceal3= true;
        }*/
      if (isNewPwdStrengthStronger(pwd)) {
        this.styleSheet = '较强';
        this.styleSheet1 = 'red1';
        this.styleSheet2 = 'red2';
        this.styleSheet3 = 'red3';
      } else if (isNewPwdStrength(pwd)) {
        this.styleSheet = '一般';
        this.styleSheet1 = 'red1';
        this.styleSheet2 = 'red2';
      } else if (pwd === '') {
        this.styleSheet = '';
        this.styleSheet1 = 'gray';
        this.styleSheet2 = 'gray';
        this.styleSheet3 = 'gray';
      } else {
        this.styleSheet1 = 'red1';
        this.styleSheet = '较弱';
      }
    },
    /* 返回上一页*/
    previousPage() {
      history.go(-1);
    },
    // 身份证校验
    isIdcard(rule, value, callback) {
      console.log(this.verifyForm.cType, '身份证校验');
      if (!isIdentity(value) && this.verifyForm.cType === 'idcard') {
        callback(new Error('身份证号码格式错误'));
      } else {
        callback();
      }
    },
    /* 邮箱校验*/
    validateEmail(rule, value, callback) {
      if (value) {
        if (isEmail(value)) {
          callback();
        } else {
          callback(new Error('邮箱账号格式错误，请重新输入'));
          this.$alert('邮箱账号格式错误，请重新输入', '警告', {
            confirmButtonText: '确定'
          });
        }
      } else {
        callback();
      }
    },
    /* 确认密码*/
    validateNewPwd2(rule, value, callback) {
      if (value) {
        if (this.activateForm.newPwd) {
          if (value === this.activateForm.newPwd) {
            callback();
          } else {
            callback(new Error('两次密码输入不一致，请重新输入'));
            this.isConceal = false;
            this.isConceal3 = true;
            this.$alert('两次密码输入不一致，请重新输入', '警告', {
              confirmButtonText: '确定'
            });
          }
        } else {
          callback(new Error('两次密码输入不一致，请重新输入'));
          this.$alert('两次密码输入不一致，请重新输入', '警告', {
            confirmButtonText: '确定'
          });
        }
      } else {
        callback();
      }
    },
    /* 校验新密码*/
    validateNewPwd(rule, value, callback) {
      if (value) {
        if (isNewPwdBlank(value)) {
          if (isNewPwdChinese(value)) {
            if (isNewPwdStrength(value)) {
              callback();
            } else {
              callback(new Error('不符合密码规则，请确认后输入！'));
              this.$alert(
                '不符合密码规则，请确认后输入！密码规则：由字母大小写，数字，特殊字符三种组成的8~16位长度字符',
                '警告',
                {
                  confirmButtonText: '确定'
                }
              );
            }
          } else {
            callback(new Error('格式错误，存在中文'));
          }
        } else {
          callback(new Error('格式错误，存在空格'));
        }
      } else {
        callback();
      }
    },

    /*
     * 手机校验
     * 带上校验成功码，发送新手机的短信（激活）
     * /user/sendSmsForNew
     * get
     * code=200发送成功，404校验码校验失败,501此手机号已被绑定
     */
    getCode(phone) {
      let that = this;
      that.phone = phone;
      sendSmsForNew({
        mobileNumber: that.phone,
        activeCode: that.activateForm.code,
        workNo: that.activateForm.workNo
      }).then((res) => {
        if (res.data.code === 200) {
          that.$message.success(res.data.msg);
        } else if (res.data.code === 501) {
          that.$refs.phone.showCut();
          that.$message.error(res.data.msg);
          return false;
        } else {
          that.$message.error(res.data.msg);
          return false;
        }
      });
    },
    /*
     * 确认激活
     * 激活用户信息（激活）
     * /user/updateInfo
     * post
     * code=200激活成功，404校验码校验失败，500系统错误
     */
    confirm() {
      let that = this;
      that.$refs.phone.confirm();
      that.$refs['activateForm'].validate((vd) => {
        if (vd && that.valid) {
          if (this.phone !== '') {
            if (this.phone === that.activateForm.phone) {
              updateInfo({
                code: that.activateForm.code,
                mobileNumber: that.activateForm.phone,
                name: that.activateForm.name,
                ids: that.activateForm.ids,
                pwd: that.activateForm.newPwd2,
                smsCode: that.activateForm.smsCode,
                workNo: that.activateForm.workNo,
                email: that.activateForm.email,
                cType: that.verifyForm.cType
              }).then((res) => {
                if (res.data.code === 200) {
                  that.$message.success(res.data.msg);
                  that.backdrop3 = 'orange';
                  that.conceal4 = false;
                  that.conceal5 = true;
                } else {
                  that.$message.error(res.data.msg);
                  return false;
                }
              });
            } else {
              that.$message.warning('手机号被修改，请重新校验！');
              return false;
            }
          } else {
            that.$message.warning('请先获取验证码，并输入正确的验证码！');
            return false;
          }
        } else {
          return false;
        }
      });
    },
    /*
     * 调用子组件confirm方法
     * 返回：
     * 电话 phone，
     * 短信 message，
     * valid 是否校验成功
     */
    returnedValue(phone, message, valid) {
      /* console.log(phone, "phone====1");
        console.log(message, "message====1");
        console.log(valid, "valid====1");*/
      this.valid = valid;
      this.activateForm.phone = phone;
      this.activateForm.smsCode = message;
    },
    cut() {
      this.backdrop2 = 'orange';
      this.conceal3 = false;
      this.conceal4 = true;
      this.activateForm.ids = this.verifyForm.verifyIds;
      this.activateForm.name = this.verifyForm.verifyName;
      this.activateForm.workNo = this.verifyForm.verifyWorkNo;
      this.$alert(
        '新密码规则：由字母大小写，数字，特殊字符三种组成的8~16位长度字符',
        '温馨提示',
        {
          confirmButtonText: '确定'
        }
      );
    },
    /*
     * 身份验证
     * 校验身份证信息（激活）
     * /user/checkIdentity
     * post
     * code=200成功，返回校验成功码，500校验失败
     */
    nextStep(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          checkIdentity({
            ids: that.verifyForm.verifyIds,
            name: that.verifyForm.verifyName,
            workNo: that.verifyForm.verifyWorkNo,
            cType: that.verifyForm.cType
          }).then((res) => {
            if (res.data.code === 200) {
              that.activateForm.code = res.data.data;

              that.$message.success(res.data.msg);
              that.cut();
            } else {
              that.$message.error(res.data.msg);
              return false;
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.gray {
  width: 60px;
  height: 15px;
  background-color: rgba(119, 136, 153, 0.2);
  margin-right: 6px;
  margin-top: 1px;
}
.red1 {
  width: 60px;
  height: 15px;
  background-color: rgba(245, 108, 108, 0.4);
  margin-right: 6px;
  margin-top: 1px;
}
.red2 {
  width: 60px;
  height: 15px;
  //background-color: rgba(255,165,0,0.7);//goldEnrod
  background-color: rgba(245, 108, 108, 0.7);
  margin-right: 6px;
  margin-top: 1px;
}
.red3 {
  width: 60px;
  height: 15px;
  //background-color: rgba(103,194,58,0.9);//green
  background-color: rgba(245, 108, 108, 1);
  margin-right: 6px;
  margin-top: 1px;
}
.orange {
  border: 1px solid rgba(199, 25, 33, 0.8);
  background-color: rgba(199, 25, 33, 0.8);
  color: rgba(255, 255, 255, 0.9);
}
.white {
  background-color: rgba(255, 255, 255, 0.8);
  color: rgba(43, 42, 51, 0.9);
  border: 1px solid rgba(43, 42, 51, 0.9);
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #f8f8f8;
  border: 1px solid white;
  color: #b6b6b6;
}
/deep/.el-form-item__label {
  font-size: 15px;
}
/deep/.el-tabs__item {
  font-size: 18px !important;
  width: 300px;
}
/deep/.el-tabs--left .el-tabs__item.is-left {
  text-align: center;
}
/deep/.el-tabs__item.is-active {
  background-color: rgba(199, 25, 33, 0.1);
}
#activateId {
  height: 900px;
  width: 100%;

  .header {
    height: 150px;
    width: 100%;
    background-image: url("../../assets/img/login/beijin1.png");
    background-position: center;
    background-size: 100% 100%;
  }
  .main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-image: url("../../assets/img/login/beijin2.jpg");
    background-position: center;
    background-size: 100% 100%;

    &-up {
      width: 100%;
      height: 60px;
      display: flex;
      color: rgba(255, 255, 255, 0.9);
      font-size: 14px;
      background-color: #c03338;
      position: relative;

      &-left {
        position: absolute;
        left: 30px;
        width: 100px;
        height: 60px;
        line-height: 60px;
      }
      &-right {
        position: absolute;
        top: 50%;
        right: 30px;
        width: 70px;
        height: 30px;
        transform: translate(0%, -50%);
        display: flex;
        cursor: pointer; //鼠标变手指
        color: white;

        &-i1 {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          cursor: pointer; //鼠标变手指
        }
        &-i2 {
          height: 30px;
          line-height: 30px;
          cursor: pointer; //鼠标变手指
        }
      }
    }
    &-below {
      width: 80%;
      height: 640px;
      display: flex;
      margin: 60px auto;
      padding: 50px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.14);

      &-tabs {
        height: 640px;
        width: 99%;
        margin: 0px auto;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
      }
      &-center {
        height: 640px;
        width: 500px;
        margin: 0px auto;

        &-div1 {
          height: 70px;
          width: 100%;
          position: relative;

          &-label1 {
            width: 120px;
            height: 30px;
            position: absolute;
            left: 0%;
            top: 50%;
            transform: translate(0%, -50%);
            display: flex;

            &-i1 {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              text-align: center;
              line-height: 30px;
            }
            &-i2 {
              width: 80px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: rgba(43, 42, 51, 0.9);
            }
          }
          &-label2 {
            border-bottom: 0.1px solid rgba(43, 42, 51, 0.3);
            width: 40px;
            position: absolute;
            left: 28%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &-label4 {
            border-bottom: 0.1px solid rgba(43, 42, 51, 0.3);
            width: 40px;
            position: absolute;
            right: 28%;
            top: 50%;
            transform: translate(-50%, 50%);
          }
          &-label3 {
            width: 120px;
            height: 30px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            /*right: 0%;
              top: 50%;
              transform: translate(0%, -50%);*/
            display: flex;

            &-i1 {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              text-align: center;
              line-height: 30px;
            }
            &-i2 {
              width: 80px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: rgba(43, 42, 51, 0.9);
            }
          }
          &-label5 {
            width: 120px;
            height: 30px;
            position: absolute;
            right: 0%;
            top: 50%;
            transform: translate(0%, -50%);
            display: flex;

            &-i1 {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              text-align: center;
              line-height: 30px;
            }
            &-i2 {
              width: 80px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: rgba(43, 42, 51, 0.9);
            }
          }
        }

        &-div2 {
          display: flex;
          height: 520px;
          width: 100%;
          flex-wrap: wrap;

          &-img {
            width: 150px;
            height: 200px;
            margin: 20px auto;
            display: flex;
            flex-wrap: wrap;

            &-i1 {
              color: rgba(199, 25, 33, 1);
              font-size: 90px;
              width: 150px;
              height: 150px;
              line-height: 150px;
              text-align: center;
            }
            &-i2 {
              color: rgba(43, 42, 51, 0.9);
              font-size: 16px;
              font-weight: 600;
              width: 150px;
              height: 50px;
              line-height: 50px;
              text-align: center;
            }
          }
          &-span {
            color: rgba(199, 25, 33, 0.6);
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            width: 120px;
            height: 40px;
            cursor: pointer; //鼠标变手指
          }
          &-span2 {
            width: 240px;
            height: 20px;
            display: flex;
            flex-wrap: wrap;

            &-styleSheet {
              width: 40px;
              height: 20px;
              color: #f56c6c;
              text-align: center;
              font-size: 12px;
            }
          }
          &-span1 {
            width: 120px;
            height: 40px;
            margin: 0px auto;
            color: rgba(199, 25, 33, 0.6);
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            cursor: pointer; //鼠标变手指
          }
          &-span3 {
            width: 100%;
            height: 30px;
            margin: 0px auto;
            color: rgba(199, 25, 33, 0.6);
            line-height: 30px;
            text-align: center;
            font-size: 13px;
          }
          &-span4 {
            width: 100%;
            height: 20px;
            color: rgba(43, 42, 51, 0.7);
            line-height: 20px;
            text-align: center;
            font-size: 11px;
            &-a {
              margin-left: 4px;
              //margin-right: 4px;
              font-size: 13px;
              font-weight: 600;
              color: rgba(210, 25, 33, 0.7);
              text-decoration: underline;
              cursor: pointer; //鼠标变手指
            }
          }
        }
      }
    }
  }
}
</style>
